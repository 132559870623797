import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import Logo from "../Logo";
import styles from "./Header.module.scss";

const Header = props => {
  const lang = props.lang;
  const pageSlug = props.pageSlug;
  const location = props.location;
  // const rootPath = `${__PATH_PREFIX__}/`

  const isLangEn = lang === "en";
  const isLangJa = lang === "ja";

  const [menu, setMenu] = useState(false);

  let langDir;

  if (isLangEn) {
    langDir = "/en/";
  } else {
    langDir = "/jp/";
  }

  useEffect(() => {
    // Only for home & en home page
    if (location.pathname === "/jp/" || location.pathname === "/en/") {
      const header = document.querySelector(".header");
      const hero = document.querySelector(".hero");
      const heroHeight = hero.offsetHeight - 80;

      const ornament1 = document.querySelector(".ornament-text--left");
      const ornament2 = document.querySelector(".ornament-text--right");

      window.addEventListener("scroll", handleScroll);

      // Prevent body scrolling in modal background
      if (menu === false) {
        document.body.classList.remove("lock-sp");
      } else {
        document.body.classList.add("lock-sp");
      }

      // Add class when user scroll
      function handleScroll() {
        let scrollY = window.pageYOffset;

        if (scrollY > heroHeight) {
          header.classList.add("is-scroll");
          ornament1.classList.add("is-scroll");
          ornament2.classList.add("is-scroll");
        } else {
          header.classList.remove("is-scroll");
          ornament1.classList.remove("is-scroll");
          ornament2.classList.remove("is-scroll");
        }
      }
    } else {
    }
  });

  return (
    <>
      <header className={`header ${styles.header} ${pageSlug}`}>
        <nav
          className={`${styles.nav_global} container-xl ${
            menu ? "is-open" : ""
          }`}
        >
          <button
            className={`nav-button ${styles.nav_button} ${
              menu ? styles.isActive : ""
            }`}
            type="button"
            onClick={() => setMenu(!menu)}
          >
            <span className={`${styles.ico_humbarger}`}></span>
          </button>

          <div className={`nav-logo ${styles.nav_logo}`}>
            <Link to={langDir}>
              <Logo />
            </Link>
          </div>

          <div className={`${styles.nav_menu} ${menu ? styles.isActive : ""}`}>
            <ul
              className={`nav-menu__lists ${styles.nav_menu__lists} no-bullet font-display`}
            >
              <li className={`${styles.nav_menu__list}`}>
                <Link
                  to={langDir + "#intro"}
                  // to={isLangJa ? langDir + "/about" : langDir + "#intro"}
                  className={`${styles.nav_menu__link} nohover`}
                  onClick={() => setMenu(!menu)}
                >
                  ABOUT
                </Link>
              </li>
              <li className={`${styles.nav_menu__list}`}>
                <Link
                  // to={langDir + "#projects"}
                  to={isLangJa ? langDir + "projects/" : langDir + "#projects"}
                  className={`${styles.nav_menu__link} nohover`}
                  onClick={() => setMenu(!menu)}
                >
                  PROJECTS
                </Link>
              </li>
              <li className={`${styles.nav_menu__list}`}>
                <Link
                  // to={langDir + "#location"}
                  to={isLangJa ? langDir + "location/" : langDir + "#location"}
                  className={`${styles.nav_menu__link} nohover`}
                  onClick={() => setMenu(!menu)}
                >
                  LOCATION
                </Link>
              </li>
              {isLangJa && (
                <>
                  <li className={`${styles.nav_menu__list}`}>
                    <Link
                      to={langDir + "podcast/"}
                      className={`${styles.nav_menu__link} nohover`}
                    >
                      PODCAST
                    </Link>
                  </li>
                  <li className={`${styles.nav_menu__list}`}>
                    <a
                      href="https://blog.misawaya.org/"
                      className={`${styles.nav_menu__link} nohover`}
                    >
                      BLOG
                    </a>
                  </li>
                  {/* <li className={`${styles.nav_menu__list}`}>[CALENDAR]</li> */}
                </>
              )}
            </ul>
          </div>

          <div
            className={`nav-lang ${styles.nav_lang} ${
              isLangJa ? styles.ja : styles.en
            }`}
          >
            <div
              className={`${styles.nav_lang__list} ${styles.nav_lang__list__ja}`}
            >
              <a href="/jp/" className={`nohover`}>
                JP
              </a>
            </div>
            <div
              className={`${styles.nav_lang__list} ${styles.nav_lang__list__en}`}
            >
              <a href="/en/" className={`nohover`}>
                EN
              </a>
            </div>
            <div className={`${styles.nav_lang__line} nav-lang__line`}></div>
          </div>
        </nav>
        <aside className="ornament-text ornament-text--right font-display">
          INADANI NAGANO
        </aside>
        <aside className="ornament-text ornament-text--left font-display">
          KOMINKA MISAWAYA
        </aside>
      </header>
    </>
  );
};

export default Header;
