import React from "react";
import { Link } from "gatsby";
import Loadable from "@loadable/component";
import logo from "../../assets/images/common/logo-thumb-black.svg";
import styles from "./Footer.module.scss";

const LoadableSocial = Loadable(() => import("../Social"));
const LoadableNewsletter = Loadable(() => import("../Newsletter"));

const Footer = props => {
  const lang = props.lang;
  let isLangJa = lang === "ja";
  let langDir;

  if (isLangJa) {
    langDir = "/jp/";
  } else {
    langDir = "/en/";
  }

  return (
    <footer className={styles.footer}>
      <div className={`${styles.inner} container-lg`}>
        <div className={styles.guide}>
          <div className={`${styles.guide_item} ${styles.guide_item__brand}`}>
            <img src={logo} alt="Kominka Misawaya" />
            {(() => {
              if (isLangJa) {
                return (
                  <p className={styles.brand__text}>
                    古民家箕澤屋は
                    <br />
                    信州伊那谷で、
                    <br className="only-for-small" />
                    江戸時代の雰囲気を感じられる
                    <br />
                    ちょっと素敵な空間です。
                  </p>
                );
              } else {
                return (
                  <p className={styles.brand__text__en}>
                    Experience History
                    <br />
                    in a traditional Japanese house
                  </p>
                );
              }
            })()}
          </div>
          <div
            className={`${styles.guide_item} ${styles.guide_item__nav} font-display`}
          >
            <Link to={langDir} className={styles.guide_item__link}>
              HOME
            </Link>
            <Link to={langDir + "#intro"} className={styles.guide_item__link}>
              ABOUT
            </Link>
            <Link
              to={isLangJa ? langDir + "/projects/" : langDir + "#projects"}
              className={styles.guide_item__link}
            >
              PROJECTS
            </Link>
            <Link
              to={isLangJa ? langDir + "/location/" : langDir + "#location"}
              className={styles.guide_item__link}
            >
              LOCATION
            </Link>
            {isLangJa && (
              <>
                <Link
                  to={langDir + "/podcast/"}
                  className={styles.guide_item__link}
                >
                  PODCAST
                </Link>
                <a
                  href="https://blog.misawaya.org/"
                  className={styles.guide_item__link}
                >
                  BLOG
                </a>
              </>
            )}
          </div>
          <div className={`${styles.guide_item} ${styles.guide_item__social}`}>
            <LoadableSocial />
          </div>
        </div>

        <div className={styles.follow}>
          <div
            id="newsletter"
            className={`${styles.follow_item} ${styles.follow_item__newsletter}`}
          >
            <h4
              className={`${styles.follow_head} ${
                isLangJa ? "" : styles.follow_head__en
              }`}
            >
              {isLangJa ? "ニュースレター" : "Newsletter"}
            </h4>
            {isLangJa ? (
              <>
                <p>
                  活動内容やメンバーのことなど、月に1回程度でお届けしています。
                  <br />
                  箕澤屋にご興味ある方はご登録お待ちしております。
                </p>
                <p style={{ marginTop: "6px" }}>
                  ※登録後に送られる確認メール承認後から、ニュースレターが届くようになります
                </p>
              </>
            ) : (
              <p className={`${styles.follow_text__en}`}>
                Join us and see our challenges!
              </p>
            )}
            <LoadableNewsletter lang={lang} />
          </div>

          <div
            className={`${styles.follow_item} ${styles.follow_item__caption} ${
              isLangJa ? "" : styles.follow_item__caption__en
            }`}
          >
            {isLangJa ? (
              <p>
                箕澤屋は、小原家の子孫である家族と、箕澤屋を残したいとの想いで集まったメンバーによって運営されている古民家保存を目的とした活動団体です。
              </p>
            ) : (
              <>
                <p>
                  We are a small organization with people who gathered for
                  Misawaya preservation.
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={`${styles.copyright} font-display container-lg`}>
        © {new Date().getFullYear()} Misawaya.
        <Link to={langDir + "privacy/"} className="link-ul">
          Privacy Policy
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
